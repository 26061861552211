<template>
  <div v-if="controlGroups">
    <div :key="control_group._id" v-for="control_group in controlGroups">
      <div
        class="card cursor-pointer"
        @click="handleCardClick(control_group._id)"
      >
        <div class="card-body">
          <h4 class="mb-0">
            {{ control_group.name }}
          </h4>
          <template v-if="expandedRows.includes(control_group._id)">
            <div class="mt-2">
                 <StandardDetailsControlsTable
              :controls="control_group.controls"
              :fields="controlFields"
            />
            </div>
           
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StandardDetailsControlsTable from "./StandardDetailsControlsTable.vue";
export default {
  name: "StandardDetailsControlGroupsSection",
  props: {
    controlGroups: {
      type: Array,
      required: true,
    },
    controlFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      expandedRows: [],
    };
  },
  components: {
    StandardDetailsControlsTable,
  },
  methods: {
    handleCardClick(id) {
      if (this.expandedRows.includes(id)) {
        this.expandedRows = this.expandedRows.filter((i) => i !== id);
      } else {
        this.expandedRows.push(id);
      }
    },
  },
};
</script>

<style>
</style>