<template>
  <div
    v-if="controlFamilies !== null && controlFamilies.length > 0"
    class="d-flex flex-column justify-content-center"
  >
    <template v-for="family in controlFamilies">
      <b-card :key="family._id" class="border rounded">
        <h3 class="mb-0">
          <strong>{{ family.name }}</strong>
        </h3>
        <div style="width: 40%">
          <b-card-text style="margin-top: 6px">
            {{ family.description }}
          </b-card-text>
        </div>

        <div class="pt-2">
          <template v-if="family.control_groups.length !== 0">
            <StandardDetailsControlGroupsSection
              :controlGroups="family.control_groups"
              :controlFields="fields"
            />
          </template>

          <!-- Controls when there is No Groups  -->
          <template v-if="family.control_groups.length === 0">
            <StandardDetailsControlsTable
              :controls="family.controls"
              :fields="fields"
            />
          </template>
        </div>
      </b-card>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BTable,
  BAvatar,
  BRow,
  VBTooltip,
  BButton,
} from "bootstrap-vue";
import StandardDetailsControlGroupsSection from "./StandardDetailsControlGroupsSection.vue";
import StandardDetailsControlsTable from "./StandardDetailsControlsTable.vue";

export default {
  name: "StandardDetailsControlsSection",
  components: {
    BCard,
    BCardText,
    BTable,
    BAvatar,
    BRow,
    BButton,
    StandardDetailsControlGroupsSection,
    StandardDetailsControlsTable,
  },
  props: {
    controlFamilies: {
      type: Array,
      required: true,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        {
          key: "control_no",
          label: "Control No",
          thClass: "text-center",
        },
        {
          key: "name",
          label: "Name",
          tdClass: "text-left font-weight-bold px-75",
        },
        {
          key: "description",
          label: "Description",
          tdClass: "text-left px-75",
        },
        {
          key: "subcontrols",
          label: "Sub Controls",
          thClass: "text-center",
        },
        {
          key: "is_applicable",
          label: "Is Applicable",
          thClass: "text-center",
        },
      ],
    };
  },
  mixins: [],
  computed: {},
  methods: {},
};
</script>

<style scoped>
.custom-column-width {
  width: 120px; /* Set your desired custom width */
}
td {
  width: 200px;
}
</style>