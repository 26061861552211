<template>
  <div>
    <b-overlay :show="show" rounded="sm" blur="0" opacity=".25">
      <b-card v-if="standard">
        <div class="m-2">
          <b-row>
            <b-col sm="6">
              <div class="row d-flex flex-column align-items-start">
                <h2 class="ml-2 text-primary font-weight-bold">
                  {{ standard.name }}
                </h2>
                <div
                  class="ml-2 d-inline-block text-truncate"
                  style="max-width: 200px"
                >
                  <p>{{ standard.description }}</p>
                </div>
                <button
                  class="ml-2 btn btn-primary"
                  @click="gotoGapassessment(standard._id)"
                >
                  Go to Gap Assessments <feather-icon icon="ArrowUpRightIcon" />
                </button>
              </div>
            </b-col>
            <b-col sm="6">
              <div
                class="w-100 d-flex align-items-center justify-content-center"
              >
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th
                      class="pb-50 d-flex align-items-start"
                      style="width: 150px"
                    >
                      <feather-icon class="mr-75" icon="AtSignIcon" />
                      <span class="font-weight-bold">Industries</span>
                    </th>
                    <!-- <td class="pb-50">
                      {{ standard.industry }}
                    </td> -->
                    <td>
                      <template
                        v-if="
                          standard.industries && standard.industries != null
                        "
                      >
                        <template v-for="(stnd, i) in standard.industries">
                          <b-badge
                            variant="primary"
                            :key="i"
                            class="mr-50 my-25"
                            >{{ stnd.name }}</b-badge
                          >
                        </template>
                      </template>
                      <span
                      v-if="
                        Array.isArray(standard.industries) &&
                        !standard.industries.length
                      "
                      ><b-badge variant="success">
                        Global
                      </b-badge>
                      </span
                    >

                    </td>
                  </tr>

                  <tr>
                    <th
                      class="pb-50 d-flex align-items-start"
                      style="width: 150px"
                    >
                      <feather-icon class="mr-75" icon="NavigationIcon" />
                      <span class="font-weight-bold">Region</span>
                    </th>
                    <td class="pb-50">
                      <template
                        v-if="
                          standard.countries &&
                          standard.countries != null &&
                          standard.countries.length > 0
                        "
                      >
                        <template v-for="(stnd, i) in standard.countries">
                          <b-badge
                            variant="primary"
                            :key="i"
                            class="mr-50 my-25"
                            >{{ stnd.name }}</b-badge
                          >
                        </template>
                      </template>
                      <span
                      v-if="
                        Array.isArray(standard.countries) &&
                        !standard.countries.length
                      "
                      ><b-badge variant="success">
                        Global
                      </b-badge></span
                    >
                    </td>
                  </tr>

                <tr>
                  <th class="pb-50" style="width: 150px">
                    <feather-icon class="mr-75" icon="InfoIcon" />
                    <span class="font-weight-bold">Version</span>
                  </th>
                  <td class="pb-50">
                    {{ standard.version }}
                  </td>
                  <td>
                    <button
                      class="btn btn-success btn-sm"
                      @click="openUpdateModal()"
                      v-if="standardupdate.status == true"
                    >
                      <feather-icon icon="AlertCircleIcon" class="mr-75" />
                      Update new version
                    </button>
                  </td>
                </tr>
              </table>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card>
        <div class="m-2">
          <h2 class="mb-3"><strong>Control Families</strong></h2>
          <div class="mx-1 mb-3 row d-flex justify-content-between">
            <!-- <div class="row d-flex justify-content-start">
              <label for="switch_1"
                ><h4 style="margin-bottom: 0" class="text-secondary">
                  Show Only Applicable Controls
                </h4></label
              >
              <b-form-checkbox
                id="switch_1"
                v-model="onlyApplicableControls"
                switch
                size="md"
                class="ml-2 mr-n2"
              ></b-form-checkbox>
            </div> -->

            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <b-button
                variant="primary"
                size="md"
                class="mr-1"
                @click="handleReimportStandardClick"
              >
                <feather-icon icon="RepeatIcon" class="mr-75" />
                <span class="text-center">Reimport Standard</span>
              </b-button>
              <!-- <div style="width: 200px">
                <b-form-select
                  class="border-primary"
                  v-model="selectedStandard"
                  :options="standardOptions"
                />
              </div> -->
            </div>
          </div>

          <StandardDetailsControlsSection
            v-if="standard"
            :controlFamilies="standard.control_families"
          />
        </div>
      </b-card>
    </b-overlay>
    <b-modal
      id="modal-update"
      cancel-variant="outline-secondary"
      ok-title="Update Standard"
      cancel-title="Close"
      centered
      title="Are you sure to update?"
      ref="modal-update"
      size="lg"
      @ok="handleReimportStandardClick"
    >
      <p><b>Change Logs</b></p>
      <div
        v-for="log in standardupdate.change_log"
        :key="log._id"
        style="padding: 5px 10px; border-radius: 5px"
        class="mb-1 border card"
      >
        <p>Internal Version : {{ log.int_version }}</p>
        <p>Field : {{ log.field }}</p>
        <p>Old Data : "{{ log.old_data }}"</p>
        <p>New Data : "{{ log.new_data }}"</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import StandardMixins from "../../mixins/StandardMixins";
import ResponseMixins from "../../mixins/ResponseMixins";
import StandardDetailsControlsSection from "./components/StandardDetailsControlsSection.vue";
import BackButton from "@/components/BackButton.vue";
import {
  BCard,
  BCol,
  BRow,
  BFormCheckbox,
  BFormSelect,
  BButton,
  VBModal,
  BOverlay,
  BBadge,
} from "bootstrap-vue";
export default {
  name: "StandardDetails",
  components: {
    BCard,
    BCol,
    BRow,
    StandardDetailsControlsSection,
    BFormCheckbox,
    BFormSelect,
    BButton,
    VBModal,
    BackButton,
    BOverlay,
    BBadge,
  },
  mixins: [StandardMixins, ResponseMixins],
  directives: {
    "b-modal": VBModal,
  },
  watch: {
    onlyApplicableControls(value) {
      if (value && this.standard !== null) {
        let newControlFamilies = this.standard["control_families"];
        newControlFamilies = newControlFamilies.map((family) => {
          if (
            family["control_groups"] &&
            family["control_groups"] !== null &&
            family["control_groups"].length > 0
          ) {
            let newControlGroups = family.control_groups;
            newControlGroups = newControlGroups.map((group) => {
              let newControls = group.controls.filter(
                (control) => control.is_applicable === true
              );
              group.controls = newControls;
              return group;
            });
            family.control_groups = newControlGroups;
          } else {
            let newControls = family.controls.filter(
              (control) => control.is_applicable === true
            );
            family.controls = newControls;
            return family;
          }
        });
        console.log(newControlFamilies);
        this.standard["control_families"] = newControlFamilies;
      } else {
        this.load();
      }
    },
    selectedStandard(newValue) {
      if (newValue !== null) {
        this.getStandardDetailsAndSetData(newValue);
      }
    },
  },
  data() {
    return {
      standardOptions: [{ value: null, text: "--Select Standard--" }],
      selectedStandard: null,
      standard: null,
      onlyApplicableControls: false,
      standardupdate: [],
      show: true,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    gotoGapassessment(standardid) {
      console.log(standardid);
      // this.$router.push(`gapassessmentfilter/${standardid}`);
      this.$router.push({
        name: "gapassessmentfilter",
        params: { id: standardid },
      });
    },
    load() {
      this.getStandardDetailsAndSetData(this.$route.params.id);
      this.getStandardsAndSetData();
      this.checkForUpdate();
    },
    openUpdateModal() {
      this.$refs["modal-update"].show();
    },
    updateStandard() {},
    checkForUpdate() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/standards/${this.$route.params.id}/check-for-update`,
      };
      this.$http(options)
        .then((resp) => {
          this.standardupdate = resp.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    handleReimportStandardClick() {
      this.showConfirmMsgBox();
    },

    showConfirmMsgBox() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(
          "All Gap Assessments will be lost, Are you sure you want to continue?",
          {
            title: "Confirm Reimport Standard",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.importedStandard(this.$route.params.id)
              .then((res) => {
                this.load();
                this.handleResponse(res);
              })
              .catch((err) => {
                this.handleError(err);
                console.log(err);
              });
            // this.deleteAssetWithId(this.$route.params.id);
            // this.$router.push({ name: "assets" });
          }
        });
    },

    getStandardDetailsAndSetData(id) {
      this.getStandardDetails(id)
        .then((res) => {
          this.standard = res.data.data;
          this.selectedStandard = res.data.data._id;
          if (this.standard) {
            this.show = false;
          }
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    getStandardsAndSetData() {
      this.getStandards()
        .then((res) => {
          let items = res.data.data.data;
          let newStandardOptions = [];
          items.map((item) => {
            newStandardOptions.push({ value: item._id, text: item.name });
          });
          this.standardOptions = newStandardOptions;
        })
        .catch((err) => {
          handleError(err);
        });
    },
  },
};
</script>

<style></style>
