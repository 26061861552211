<template>
  <b-table
    responsive
    class="mt-1 mb-2 table-bordered table-condensed"
    :fields="fields"
    :items="controls"
    @row-clicked="handleRowClick"
    :tbody-tr-class="rowClasses"
    hover
  >
    <template #cell(control_no)="data">
      <div v-if="data != null" class="d-flex justify-content-center">
        <b-badge variant="primary">
          {{ data.value }}
        </b-badge>
      </div>
    </template>

    <template #cell(name)="data">
      <div
        v-if="data != null"
        class="d-flex justify-content-start"
        style="max-width: 25vw"
        v-b-tooltip.hover.top.v-secondary
        :title="data.value"
      >
        <p
          class="font-weight-bold mb-0"
          :class="{
            'text-truncate': !expandedList.includes(data.item._id),
          }"
        >
          {{ data.value }}
        </p>
      </div>
    </template>

    <template #cell(description)="data">
      <div
        v-if="data != null"
        class="d-flex justify-content-start cursor-pointer"
        style="max-width: 25vw"
      >
        <p
          class="font-weight-bold mb-0"
          :class="{
            'text-truncate': !expandedList.includes(data.item._id),
          }"
        >
          {{ data.value }}
        </p>
      </div>
    </template>

    <template #cell(subcontrols)="data">
      <div
        v-if="data != null"
        class="cursor-pointer d-flex justify-content-center"
      >
        <b-avatar variant="light-warning">
          <span>{{ data.value.length }}</span>
        </b-avatar>
      </div>
    </template>

    <template #cell(is_applicable)="data">
      <b-row class="d-flex justify-content-center">
        <b-avatar
          class="border-danger"
          v-if="data.value === false"
          variant="light-danger"
          ><p class="mb-0">No</p></b-avatar
        >
        <b-avatar
          class="border-success"
          v-if="data.value === true"
          variant="light-success"
          ><p class="mb-0">Yes</p></b-avatar
        >
      </b-row>
    </template>

    <template #row-details="row" >
      <div class="card bg-transparent my-0"  v-if="row.item.subcontrols && row.item.subcontrols!=null && row.item.subcontrols.length>0">
        <div class="card-header m-0 p-0"></div>
        <div class="w-100 d-flex align-items-center justify-content-start mt-1">
          <h5 class="card-title mb-0 text-start">Subcontrols</h5>
        </div>
        <template>
          <li
            v-for="(re, i) in row.item.subcontrols"
            :key="i"
            class="my-1 d-flex align-items-start justify-content-start"
          >
            <b-badge variant="light-primary" class="mr-75">{{
              re.control_no
            }}</b-badge>
            <div
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <p class="font-weight-bold mb-25">
                {{ re.name }}
              </p>
              <small class="font-weight-normal mb-0">
                {{ re.description }}
              </small>
            </div>
          </li>
        </template>
      </div>
    </template>
  </b-table>
</template>

<script>
import {
  BTable,
  BCard,
  BRow,
  BAvatar,
  BFormSelect,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import BCardCode from "@/@core/components/b-card-code/BCardCode.vue";
export default {
  data() {
    return {
      expandedList: [],
    };
  },
  name: "StandardDetailsControlsTable",
  props: {
    controls: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  components: { BTable, BCard, BRow, BAvatar, BFormSelect, BBadge, BCardCode },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    handleRowClick(item) {
      if (this.expandedList.includes(item._id)) {
        let newExpandedList = this.expandedList.filter((i) => i != item._id);
        this.expandedList = newExpandedList;
        item._showDetails = false;
      } else {
        item._showDetails = true;
        let newExpandedList = [...this.expandedList];
        newExpandedList.push(item._id);
        this.expandedList = newExpandedList;
      }
    },

    rowClasses(item, index) {
      if (this.expandedList.includes(item._id)) {
        return "clickable-row border-light";
      } else {
        return "clickable-row";
      }
    },
  },
};
</script>

<style scoped>


.clickable-row:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
</style>